import React from 'react';

import { graphql } from 'gatsby';

import { Section as NotFoundPageSection } from '@sections/404';

const NotFoundPage = () => <NotFoundPageSection />;

export default NotFoundPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
