import styled from 'styled-components';

import { Breakpoints, Colors, Fonts } from '@common/theme';
import { Button as DefaultButton } from '@components/Button';

export const Wrapper = styled.section`
  overflow: hidden;
  height: 100vh;
  display: flex;
  align-items: center;
`;

export const Title = styled.h1`
  color: ${Colors.primary};
  font-weight: ${Fonts.weight.bold};
  font-size: 30px;

  ${Breakpoints.lg`
    font-size: 44px;
  `}
`;

export const Subtitle = styled.span`
  color: ${Colors.primary};
  font-weight: ${Fonts.weight.light};
  font-size: 16px;
  line-height: 1.7;
`;

export const Button = styled(DefaultButton)`
  margin-top: 1rem;
`;

export const Logo = styled.img`
  width: 150px;
  height: auto;
  /* filter: grayscale(1); */

  ${Breakpoints.lg`
    width: 215px;
  `}
`;
