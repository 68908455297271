import React from 'react';

import { useTranslation } from 'gatsby-plugin-react-i18next';
import { Col, Container, Row } from 'react-bootstrap';

import { Button, Subtitle, Title, Wrapper, Logo } from './styles';

export const Section = () => {
  const { t } = useTranslation();

  return (
    <Wrapper id="home">
      <Container>
        <Row>
          <Col>
            <Logo src="/images/logo.webp" alt={t('meta.sitename')} />
            <Title>{t('notFoundPage.title')}</Title>
            <Subtitle>{t('notFoundPage.description')}</Subtitle>
            <div>
              <Button href="/">{t('notFoundPage.button')}</Button>
            </div>
          </Col>
        </Row>
      </Container>
    </Wrapper>
  );
};
